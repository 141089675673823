define("auth-frontend/mirage/scenarios/staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.loadFixtures('companies');
    server.create('user', {
      id: 'details',
      email: 'tod.user@mutual.com',
      lastName: 'User',
      username: 'tod.user',
      isStaff: true,
      isSuperuser: true
    });
  }
});