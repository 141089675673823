define("auth-frontend/mirage/factories/user", ["exports", "ember-cli-mirage", "chance"], function (_exports, _emberCliMirage, _chance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var chance = new _chance.default();

  var _default = _emberCliMirage.Factory.extend({
    email: function email() {
      return chance.email();
    },
    username: function username() {
      return chance.word();
    },
    firstName: function firstName() {
      return chance.first();
    },
    lastName: function lastName() {
      return chance.last();
    },
    companyNumber: 9690,
    hasMfa: false,
    isActive: true,
    isStaff: false,
    isSuperuser: false
  });

  _exports.default = _default;
});