define("auth-frontend/mirage/factories/permission", ["exports", "ember-cli-mirage", "chance"], function (_exports, _emberCliMirage, _chance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var chance = new _chance.default();

  var _default = _emberCliMirage.Factory.extend({
    code: function code() {
      return chance.word();
    },
    description: function description() {
      return chance.word();
    },
    system: function system() {
      return chance.word();
    }
  });

  _exports.default = _default;
});