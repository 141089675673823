define("auth-frontend/mirage/fixtures/companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    company_logo_url: 'https://s3-us-west-2.amazonaws.com/imtapps-imaging-test-public/company_logos/5437f21b9c79d84e6b7f984e',
    company_id: 9690,
    id: '9690',
    company_name: 'Demo Company',
    clean_website: 'http://google.com',
    address1: '4445 Corporate Dr',
    address2: 'Unit 2',
    city: 'West Des Moines',
    state: 'IA',
    zip_code: 503232,
    primary_phone: '123-234-3456',
    mips_id: null,
    apps_path: 'apps7701'
  }];
  _exports.default = _default;
});