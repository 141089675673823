define("auth-frontend/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.create('user', {
      id: '8'
    });
    server.create('user', {
      id: 'details',
      companyNumber: 7732,
      email: 'tod.user@mutual.com',
      firstName: 'Tod',
      isActive: true,
      isStaff: true,
      isSuperuser: true,
      lastName: 'User',
      username: 'tod.user'
    });
    server.create('company', {
      company_logo_url: 'https://s3-us-west-2.amazonaws.com/imtapps-imaging-test-public/company_logos/5437f21b9c79d84e6b7f984e',
      company_id: 9690,
      id: '9690',
      company_name: 'Demo Company',
      clean_website: 'http://google.com',
      address1: '4445 Corporate Dr',
      address2: 'Unit 2',
      city: 'West Des Moines',
      state: 'IA',
      zip_code: 503232,
      primary_phone: '123-234-3456',
      mips_id: null,
      apps_path: 'apps7701'
    });
    server.create('company', {
      company_id: 7732,
      id: '7732',
      company_logo_url: 'https://s3-us-west-2.amazonaws.com/imtapps-imaging-test-public/b385c2a7-990d-4e4a-904d-212f58103eb2',
      clean_website: '/',
      address1: '103 N Main St',
      address2: '',
      city: 'Des Moines',
      state: 'IA',
      zip_code: '50028',
      zip_plus: '',
      mips_id: '',
      company_name: 'QA APPS Mutual',
      primary_phone: '515-453-0678',
      apps_path: 'APPS7732'
    });
    server.create('permission');
    var userDetails = server.create('user', {
      username: 'tod.user',
      companyNumber: 7732,
      hasMfa: true
    });
    var user1 = server.create('user', {
      companyNumber: 7732
    });
    var user2 = server.create('user', {
      companyNumber: 7732
    });
    var user3 = server.create('user', {
      companyNumber: 7732
    });
    var inactive = server.create('user', {
      isActive: false,
      companyNumber: 7732
    });
    var invited = server.create('user', {
      isActive: false,
      companyNumber: 7732,
      username: 'INVITED USER DEMO COMPANY'
    });
    var userProxy1 = server.create('user-proxy', {
      user: user1,
      agency: '024',
      companyId: 7732
    });
    var userProxy2 = server.create('user-proxy', {
      user: user2,
      companyId: 7732
    });
    var userProxy3 = server.create('user-proxy', {
      user: user3,
      companyId: 7732
    });
    server.create('user-proxy', {
      user: inactive,
      companyId: 7732
    });
    server.create('user-proxy', {
      user: invited,
      companyId: 7732
    });
    server.create('user-proxy', {
      user: userDetails,
      companyId: 7732
    });
    var perm1 = server.create('permission', {
      groups: server.createList('group', 3, {
        companyId: 7732,
        users: [user1]
      }),
      userProxies: [userProxy1, userProxy2]
    });
    var perm2 = server.create('permission', {
      groups: server.createList('group', 3, {
        companyId: 7732
      }),
      userProxies: [userProxy3]
    });
    var perm3 = server.create('permission', {
      groups: server.createList('group', 4, {
        companyId: 7732,
        users: [user1]
      })
    });
    server.create('company-permission', {
      id: 7732,
      permissions: [perm1, perm2, perm3]
    });
    server.createList('group', 2, {
      companyId: 7732,
      users: [user1, user2]
    });
    server.createList('group', 3);
    server.create('user-proxy', {
      user: server.create('user', {
        companyNumber: 7732
      }),
      companyId: 7732,
      insured: true
    });
    server.create('user-proxy', {
      user: server.create('user', {
        companyNumber: 7732
      }),
      companyId: 7732,
      insured: true
    });
    server.create('user-proxy', {
      user: server.create('user', {
        companyNumber: 7732
      }),
      companyId: 7732,
      api: true
    });
    server.create('user-proxy', {
      user: server.create('user', {
        companyNumber: 7732
      }),
      companyId: 7732,
      api: true
    });
  }
});