define("auth-frontend/mirage/factories/group", ["exports", "ember-cli-mirage", "chance"], function (_exports, _emberCliMirage, _chance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var chance = new _chance.default();

  var _default = _emberCliMirage.Factory.extend({
    name: function name() {
      return chance.word();
    },
    description: function description() {
      return chance.word();
    },
    companyId: 9690
  });

  _exports.default = _default;
});