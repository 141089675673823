define("auth-frontend/mirage/config", ["exports", "auth-frontend/config/environment", "miragejs"], function (_exports, _environment, _miragejs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var agencies = [{
      agency_id: '624fea3f0c5c1d5908c5c25e587165b6',
      agency_name: 'A.K. INSURANCE AGENCY',
      name: 'A.K. INSURANCE AGENCY',
      is_active: true,
      agency_code: '024',
      company: 7732
    }, {
      agency_id: '958b56b093dc9db1df18b303727f7730',
      agency_name: 'Bad Agency',
      name: 'Bad Agency',
      agency_code: '030',
      is_active: true,
      company: 7732
    }, {
      agency_id: '58eff6714e6b193c25fcb1c601883aab',
      agency_name: 'AMBROSE INSURANCE AGENCY',
      name: 'AMBROSE INSURANCE AGENCY',
      agency_code: '069',
      is_active: true,
      company: 7732
    }, {
      agency_id: '45aa289477b607eadf663afb6a31b61d',
      agency_name: 'A.K. INSURANCE AGENCY',
      name: 'A.K. INSURANCE AGENCY',
      is_active: true,
      agency_code: '024',
      company: 9690
    }, {
      agency_id: '1297138e760d9445b5cb32b522b3d7f1',
      agency_name: 'Bad Agency',
      name: 'Bad Agency',
      agency_code: '030',
      is_active: true,
      company: 9690
    }, {
      agency_id: '8dfa785b262013c484084f13891650c9',
      agency_name: 'AMBROSE INSURANCE AGENCY',
      name: 'AMBROSE INSURANCE AGENCY',
      agency_code: '069',
      is_active: true,
      company: 9690
    }];
    this.passthrough();
    this.get("".concat(_environment.default.authUrl, "/api/user/details/"), function (_ref) {
      var users = _ref.users;
      return users.find('details');
    });
    this.get("".concat(_environment.default.authUrl, "/:id/api/company-permissions/"));
    this.patch("".concat(_environment.default.authUrl, "/:id/api/company-permissions/"));
    this.get("".concat(_environment.default.authUrl, "/:company/api/permissions/"), function (schema, request) {
      var filtered_perms = schema.permissions.all().models;

      if (request.queryParams['filter[company_permissions__company_id]']) {
        filtered_perms = filtered_perms.filter(function (perm) {
          if (perm.companyPermissionIds) {
            return perm.companyPermissionIds.includes(request.queryParams['filter[company_permissions__company_id]']);
          }
        });
      }

      if (request.queryParams['filter[groups]']) {
        filtered_perms = filtered_perms.filter(function (perm) {
          if (perm.groupIds) {
            return perm.groupIds.includes(request.queryParams['filter[groups]']);
          }
        });
      }

      if (request.queryParams['filter[user_proxies__user]']) {
        filtered_perms = filtered_perms.filter(function (perm) {
          if (perm.userProxyIds) {
            return perm.userProxyIds.filter(function (id) {
              return schema.userProxies.find(id).userId === request.queryParams['filter[user_proxies__user]'];
            }).length > 0;
          }
        });
      }

      return schema.permissions.where(function (perm) {
        return filtered_perms.any(function (filter_perm) {
          return filter_perm.id === perm.id;
        });
      });
    });
    this.post("".concat(_environment.default.authUrl, "/:company/api/permissions/"));
    this.get("".concat(_environment.default.authUrl, "/:company/api/permissions/:id"));
    this.patch("".concat(_environment.default.authUrl, "/:company/api/permissions/:id"));
    this.get('https://configtest.imtapps.com/api/company/:company/', function (schema, request) {
      var foundCompany = schema.companies.findBy({
        company_id: request.params.company
      });

      if (foundCompany) {
        return foundCompany.attrs;
      }

      return schema.companies.create({
        company_id: request.params.company
      });
    });
    this.get("".concat(_environment.default.authUrl, "/:company/api/users/"), function (_ref2, request) {
      var users = _ref2.users;

      if (request.queryParams['filter[group]']) {
        var groupUsers = users.where(function (user) {
          if (user.groupIds) {
            return user.groupIds.includes(request.queryParams['filter[group]']);
          }
        });
        var activeUsers = groupUsers.models.filter(function (user) {
          return user.isActive;
        });
        return users.where(function (user) {
          return activeUsers.map(function (au) {
            return au.id;
          }).includes(user.id);
        });
      }

      if (request.queryParams['filter[username]']) {
        var filteredUsers = users.where(function (user) {
          return user.username === request.queryParams['filter[username]'] && user.id !== 'details';
        });

        if (request.queryParams['fields[users]'] === 'username') {
          return filteredUsers.models.map(function (user) {
            return {
              username: user.username
            };
          });
        }

        return filteredUsers;
      }

      if (request.queryParams['filter[is_active]']) {
        return users.where({
          isActive: true
        });
      }

      return users.where({
        companyNumber: request.params.company
      });
    });
    this.get("".concat(_environment.default.authUrl, "/:company/api/users/:id/"));
    this.post("".concat(_environment.default.authUrl, "/:compary/api/users/"));
    this.del("".concat(_environment.default.authUrl, "/:compary/api/users/:id"), function (schema, request) {
      schema.userProxies.where(function (userProxy) {
        return userProxy.userId === request.params.id;
      }).destroy();
      return schema.users.find(request.params.id).destroy();
    });
    this.patch("".concat(_environment.default.authUrl, "/:compary/api/users/:id"));
    this.post("".concat(_environment.default.authUrl, "/:company/api/users/:userId/resend-invite/"), function (schema, request) {
      if (request.params.company === 'undefined') {
        return new _miragejs.Response(404);
      }

      return new _miragejs.Response(204);
    });
    this.post("".concat(_environment.default.authUrl, "/:company/api/users/:userId/copy"), function (_ref3, request) {
      var users = _ref3.users,
          userProxies = _ref3.userProxies;
      var user = users.create({
        username: "TEMP USER - 9690 - ".concat(request.params.userId + 1)
      });
      userProxies.create({
        user: user
      });
      return user;
    });
    this.get("".concat(_environment.default.authUrl, "/:company/api/user-proxies/:id/")); // eslint-disable-next-line complexity

    this.get("".concat(_environment.default.authUrl, "/:company/api/user-proxies/"), function (schema, request) {
      var userProxies = schema.userProxies.where({
        companyId: request.params.company
      });

      if (request.queryParams['filter[insured]'] === 'False') {
        userProxies = userProxies.filter(function (userProxy) {
          return !userProxy.insured;
        });
      }

      if (request.queryParams['filter[apiUsersOnly]'] === 'False') {
        userProxies = userProxies.filter(function (userProxy) {
          return !userProxy.apiUsersOnly;
        });
      }

      if (request.queryParams['filter[permissions]']) {
        userProxies = userProxies.filter(function (userProxy) {
          if (userProxy.permissionIds) {
            return userProxy.permissionIds.includes(request.queryParams['filter[permissions]']);
          }
        });
      }

      if (request.queryParams['filter[user__is_active]']) {
        userProxies = userProxies.filter(function (userProxy) {
          var user = schema.users.find(userProxy.userId);

          if (user.isActive === true) {
            return userProxy;
          }
        });
      }

      if (request.queryParams['filter[user__group]']) {
        userProxies = userProxies.filter(function (userProxy) {
          var user = schema.users.find(userProxy.userId);
          return user.groupIds.includes(request.queryParams['filter[user__group]']);
        });
      }

      if (request.queryParams['filter[user__group__permissions]']) {
        userProxies = userProxies.filter(function (userProxy) {
          var user = schema.users.find(userProxy.userId);
          return user.groupIds.any(function (id) {
            return schema.groups.find(id).permissionIds.includes(request.queryParams['filter[user__group__permissions]']);
          });
        });
      }

      return userProxies;
    });
    this.post("".concat(_environment.default.authUrl, "/:company/api/user-proxies/"));
    this.del("".concat(_environment.default.authUrl, "/:company/api/user-proxies/:id/"));
    this.patch("".concat(_environment.default.authUrl, "/:company/api/user-proxies/:id/"));
    this.get("".concat(_environment.default.authUrl, "/:company/api/groups"), function (schema, request) {
      if (request.queryParams['filter[permissions]']) {
        return schema.groups.where(function (group) {
          if (group.permissionIds) {
            return group.permissionIds.includes(request.queryParams['filter[permissions]']);
          }
        });
      }

      if (request.queryParams['filter[users]']) {
        return schema.groups.where(function (group) {
          if (group.userIds) {
            return group.userIds.includes(request.queryParams['filter[users]']);
          }
        });
      }

      return schema.groups.where({
        companyId: parseInt(request.params.company)
      });
    });
    this.get("".concat(_environment.default.authUrl, "/:company/api/groups/:id/"));
    this.patch("".concat(_environment.default.authUrl, "/:company/api/groups/:id/"));
    this.post("".concat(_environment.default.authUrl, "/:company/api/groups/"));
    this.del("".concat(_environment.default.authUrl, "/:company/api/groups/:id/"));
    this.get('https://configtest.imtapps.com/api/agency/', function () {
      return {
        objects: agencies
      };
    });
    this.get('https://configtest.imtapps.com/api/agency/:agencyId', function (schema, request) {
      return agencies.find(function (agency) {
        return agency.agency_id === request.params.agencyId;
      });
    });
    this.post(_environment.default.appsCoreEnvironments.test.apiDomains.eventNotificationUrl, function (schema, request) {
      var note = schema.eventNotifications.create(JSON.parse(request.requestBody));
      return {
        id: note.id
      };
    });
    this.post("".concat(_environment.default.authUrl, "/mfa/disable/:id/"), function () {
      return '';
    });
    this.get('https://configtest.imtapps.com/api/public/company/:api_key/', function () {
      var publicCompanyData = {
        company_logo_url: 'https://s3-us-west-2.amazonaws.com/imtapps-imaging-test-public/562077ca9c79d83e25fa2e16',
        company_id: 7701,
        company_name: 'Demo Company',
        clean_website: 'http://www.apps-system.com/'
      };
      return JSON.stringify(publicCompanyData);
    });
    this.get('https://configtest.imtapps.com/api/company/:company/links/', function () {
      return {
        data: []
      };
    });
    this.post('https://insuredapitest.imtapps.com/auth/9690/policies/null/');
    this.post('https://insuredapitest.imtapps.com/auth/9690/policies/1/');
    this.get('https://insuredapitest.imtapps.com/auth/9690/policies/1/');
    this.get('https://insuredapitest.imtapps.com/auth/9690/policies/2/');
    this.get('https://insuredapitest.imtapps.com/auth/9690/policies/null/');
    this.get('https://insuredapitest.imtapps.com/auth/9690/policies/10/');
    this.get('https://insuredapitest.imtapps.com/auth/7732/policies/16/');
  }
});